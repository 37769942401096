import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mobile } from '../common/style/mixins';
import { COLOR } from '../common/style/variables';

const JourneySectionStyled = styled.div`
  padding: 48px 0 104px;

  .section-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 10%;

    ${mobile(css`
      display: block;

      h2,
      p {
        margin-bottom: 24px !important;
      }

      .play-pause {
        float: right;
      }
    `)}

    h2,
    p {
      flex-basis: 45%;
      margin: 0;
    }

    .play-pause {
      margin-left: auto;
    }
  }

  .section-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    margin-top: 84px;

    .journey-card {
      display: flex;
      flex-flow: column nowrap;
      flex: 30%;

      ${mobile(css`
        margin-top: 56px;
      `)}

      h3 {
        margin: 32px 0 11px;
      }

      p {
        font-size: 16px;
      }

      ul {
        list-style: disc;
        margin-top: auto;
        margin-bottom: 0;

        li {
          font-size: 16px;
          color: ${COLOR.woodSmoke};
        }
      }

      a.btn {
        display: flex;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        width: 200px;
        margin-top: 40px;
      }

      .lottie {
        height: 332px;
      }
    }
  }
`;

export default JourneySectionStyled;
