import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import Reveal from '@/components/reveal/Reveal';
import { BaseComponentProps } from '@/types';

import JourneySectionStyled from './JourneySectionStyled';

import Lottie1 from '@/../static/assets/lottie/lottie-1.json';
import Lottie2 from '@/../static/assets/lottie/lottie-2.json';
import Lottie3 from '@/../static/assets/lottie/lottie-3.json';
import playIcon from '@/../static/assets/images/play-black.png';
import pauseIcon from '@/../static/assets/images/pause-black.png';

const Lottie = loadable(() => import(`lottie-react`));

const JourneySection: React.FC<BaseComponentProps> = () => {
  const lottieRef = {
    ref1: useRef(null),
    ref2: useRef(null),
    ref3: useRef(null),
  };
  const [imgVideoUrl, setImgVideoUrl] = useState(playIcon);
  const [isLottiePlaying, setIsLottiePlaying] = useState(true);
  const handlePlayLottie = () => {
    // toggle video play and pause
    setIsLottiePlaying(!isLottiePlaying);
    if (isLottiePlaying) {
      lottieRef.ref1.current.pause();
      lottieRef.ref2.current.pause();
      lottieRef.ref3.current.pause();
    } else {
      lottieRef.ref1.current.play();
      lottieRef.ref2.current.play();
      lottieRef.ref3.current.play();
    }
  };

  useEffect(() => {
    setImgVideoUrl(!isLottiePlaying ? playIcon : pauseIcon);
  }, [isLottiePlaying]);

  return (
    <JourneySectionStyled>
      <div className="container">
        <Reveal className="section-header">
          <h2>With you for the journey</h2>
          <p>
            Whether you&lsquo;re starting from scratch or looking to modernise
            an existing platform, we have the ecommerce expertise to support you
            - whatever stage you&lsquo;re in
          </p>
          <div
            role="button"
            className="play-pause"
            onClick={handlePlayLottie}
            onKeyDown={handlePlayLottie}
            tabIndex={0}
          >
            <img src={imgVideoUrl} alt="Toggle" width="40" height="40" />
          </div>
        </Reveal>

        <Reveal className="section-content">
          <div className="journey-card">
            <Lottie
              lottieRef={lottieRef.ref1}
              className="lottie"
              animationData={Lottie1}
              loop
            />
            <h3>Inception</h3>
            <p>
              The beginning of your journey into ecommerce and digital customer
              experiences
            </p>
            <ul>
              <li>
                <p>Opportunity analysis</p>
              </li>
              <li>
                <p>Platform selection</p>
              </li>
              <li>
                <p>Design and development</p>
              </li>
            </ul>
            <a href="/contacts#contact-form" className="btn">
              Get Started
            </a>
          </div>
          <div className="journey-card">
            <Lottie
              lottieRef={lottieRef.ref2}
              className="lottie"
              animationData={Lottie2}
              loop
            />
            <h3>Transition</h3>
            <p>
              The continuation of your journey into best-of-breed digital
              commerce, unrivalled customer experiences and composable
              technology
            </p>
            <ul>
              <li>
                <p>Replatforming</p>
              </li>
              <li>
                <p>Optimisation</p>
              </li>
              <li>
                <p>Next-gen customer experience</p>
              </li>
            </ul>
            <a href="/contacts#contact-form" className="btn">
              Let&lsquo;s Transform
            </a>
          </div>
          <div className="journey-card">
            <Lottie
              lottieRef={lottieRef.ref3}
              className="lottie"
              animationData={Lottie3}
              loop
            />
            <h3>Expansion</h3>
            <p>
              The evolution to harnessing high-value digital commerce
              opportunities with sustainable solutions that scale on demand
            </p>
            <ul>
              <li>
                <p>Commercial transformation</p>
              </li>
              <li>
                <p>Omnichannel and B2X</p>
              </li>
              <li>
                <p>New and emerging markets</p>
              </li>
            </ul>
            <a href="/contacts#contact-form" className="btn">
              Grow with us
            </a>
          </div>
        </Reveal>
      </div>
    </JourneySectionStyled>
  );
};

export default JourneySection;
